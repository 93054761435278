.container {
    background-color: black;
    border-radius: 5vw;
    margin-top: 2vh;
    width: 80%;
    height: 6vh;
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.text {
    font-size: 1.5rem;
}