input:focus, textarea:focus {
    outline: 0;
    background-color: rgba(200, 200, 200, 0.3);;
}

input::placeholder {
    font-size: large;
}

textarea::placeholder {
    font-size: x-large;
}

@media (max-width: 767px) {
    .container {
        width: 100%;
    }
}

@media (min-width: 768px) {
    .container {
        width: 100%;
    }
}

.container {
    width: 100%;
    padding-bottom: 5vh;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

input, textarea {
    width: 100%;
    background-color: #ffffff;
    padding: .6em;
    margin: .8em;
    border-radius: 1em;
    border: 1px solid rgba(200, 200, 200, 0.5);
    font-size: large;
}

.inputFormField {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

@media (max-width:767px) {
    .formField {
        width:80%;
    }
}

@media (min-width:768px) {
    .formField {
        width:80%;
    }
}

.formField {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.required
{
    float: left;
    color: #de2121;
    font-weight: bold;
    font: 1em sans-serif;
}

.inputError {
    border-width: .1em;
    border-color: #d11d1d;
}

.asterisk
{
    width: 5%;
    color: red;
    font-weight: bold;
    font: 1.2em sans-serif;
}

.submitBtn {
    outline: 0;
    padding: .5em;
    width: 50%;
    font-size: 30px;
    color: #353535;
    font-weight: bold;
    background: linear-gradient(to left,#B2B0B0, #E8E7E7);
    border-radius: 2em;
    border-color: #ffffff;
    border-bottom-width: .3em;
    border-bottom-color: #ffffff;
}

.submitBtn:hover {
    background: linear-gradient(to left,#A9F3F6, #5B8CAE);
    color: white;
}

h1 {
    font-size: 3em;
}