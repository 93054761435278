.App {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.innerApp {
    align-self: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
