.container {
    width: 100%;
}

.row {
    width: 100%;
    align-self: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
}