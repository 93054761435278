.container {
    margin-top: 2vh;
}

.LevomTmText {
    font-size: 0.5rem;
    position: absolute;
}

.levomNavlink {
    text-decoration: none;
}

.BelowNavbar {
    /* margin-left: 4vw; */
    margin-top: 1.5vh;
}

.BelowNavbarText1 {
    font-size: 0.5rem;
    color: #eee;
}

.BelowNavbarText3 {
    width: min-content;
}

.threedots {
    margin-left: 5px;
}

.learnImage {
    margin-bottom: 10px;
    width: 100%;
}

span {
    color: white;
    font-family: 'Oxanium';
}

.LevomText {
    margin-top: 4vw;
    font-size: 2rem;
}