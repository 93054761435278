.container {
    width: 100%;
}

.innerContainer{
    width: 100%;
    align-self: center;
}

p, strong ,span, h1 {
    color: white;
}

a, li {
    color: #2141B0;
}
