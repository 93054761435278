.levomLogo {
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 .6rem;
  height: 100%;
  margin-top: auto;
  margin-bottom: auto;
  color: #DFE6EA;
  font-size: .7rem;
  font-weight: bold;
  text-decoration: none;
  text-align: center;
  transition: .5s;
}

.topnav {
    width: 100%;
    height: 9%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #203756;
    overflow: hidden;
}

.innerNav {
  width: 70%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.topnav a:hover {
  color: #00093E;
}

.activeNav {
  background-color: #38506C;
}