.container {
    /* padding-bottom: 4vh; */
    position: relative;
}

.row1 {
    align-self: center;
}

.row1TextsContainer {
    background: linear-gradient(to top,#000000, #6D6E74);
    margin-left: 25px;
    margin-right: 25px;
}

.text {
    margin-left: 5px;
    margin-right: 5px;
    font-size: 1.1rem;
}

.footerIcon {
    position: absolute;
    bottom: 0px;
    width: 100px;
    height: 120px;
}

.image {
    width: 50px;
    height: 70px;
}

.allRightsContainer {
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.allRightsInnerContainer {

}

.allRights {
    font-size: .8rem;
}

.allRightsTm {
    font-size: .4rem;
    position: absolute;
}