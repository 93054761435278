.text {
    text-align: center;
    background-color: #07122A;
}

.img {
    width: 140px;
    height: 130px;
}

.storeImg {
    width: 140px;
    height: 40px;
    margin-top: 10px;
}

.storeImg:hover {
    cursor: pointer;
}
