.container {
    width: 70%;
    align-self: center;
    align-items: center;
    justify-content: center;
}

.text {
    text-align: center;
    width: 70%;
    color: #2141B0;
}

.img {
    width: 50px;
    height: 70px;
}