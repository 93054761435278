.row {
    width: 100%;
    align-self: center;
}

.title {
    font-size: 1.8rem;
    font-weight: 600;
}

.img {
    width: 50px;
    height: 75px;
}