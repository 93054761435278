.container {
    width: 100%;
    align-self: center;
    margin: 5px;
}

.textContainer {
    background-color: black;
    border-radius: 5vw;
    width: 85%;
    align-self: center;
    padding: 10px;
    margin-left: 10px;
}

.text {
    font-size: 0.8rem;
    color: white;
    text-align: left;
}

.img {
    width: 40px;
    height: 40px;
}