.container {
    width: 100%;
    align-self: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #999999;
    padding-bottom: 5vh;
}

.footerLink {
    font-size: .9rem;
    color: #ccc;
    border: none;
    outline: none;
    text-decoration: none;
}

.footerLink:hover {
    color:white;
}

.footerLinkContainer {
    margin-top: 10px;
    padding: 0px 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.linkDot {
    margin-right: 6px;
    height: 8px;
    width: 8px;
    background-color: #777;
    border-radius: 50%;
}

.linkText {
    transition: color 0.5s;
}
