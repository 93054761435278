.container {
    width: 100%;
    align-self: center;
    justify-content: center;
    flex-direction: row-reverse;
}

.img {
    margin: 10px;
    width: 40px;
    height: 40px;
}

.img:hover {
    cursor: pointer;
    width: 45px;
    height: 45px;
}