.App {
    width: 100%;
    display: flex;
    flex-direction: column;
}

span {
  color: white;
  font-family: 'Oxanium';
}

.Carousel {
  margin-top: 2vh;
}

.Navbar {
  width: 100%;
  height: 5vh;
  display: flex;
  flex-direction: column;
}
